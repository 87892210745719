import React, { useContext, useEffect, useState } from "react";
import styles from './Header.module.css';
import { ReactComponent as Burger } from '../../components/imgs/icons/two lines-27.svg'
import { ReactComponent as Cross } from '../../components/imgs/icons/cross-33.svg'
import { NavLink, useNavigate } from "react-router-dom";
import { LoggedInContext } from "../providers/LoggedInContext";
import UserIcon from "../userIcon/UserIcon";
import { BrandCountryContext } from "../providers/BrandCountryProvider";
import UserIconMobile from "../userIcon/UserIconMobile";

export default function Header(){
    const navigate = useNavigate();
    const {loggedIn,handleLogOut,handleCheckLogin,user} = useContext(LoggedInContext);
        const {brand,setBrand,country,setCountry,disable,setDisable,currentPage,changerActive,setChangerActive} = useContext(BrandCountryContext);
    

    const [mobileVersion,setMobileVersion] = useState(true);
    const [navBarShow,setNavBarShow] = useState(false);
    const [headerPos,setHeaderPos] = useState(0);

    const toggleNavBar = () => {
        setNavBarShow((prev) => !prev);
    }
    
    const onResizeFunction = () => {
        const newViewportWidth = window.innerWidth;
        setMobileVersion(newViewportWidth < 1000);
        setNavBarShow(newViewportWidth > 1000);
    }

    useEffect(() => {
        onResizeFunction();
        window.addEventListener('resize', onResizeFunction);
        return () => {
            window.removeEventListener('resize', onResizeFunction);
        }
    },[]);

    
    const handleLogoClick = () => {
        navigate('/home');
    }

    const handleNavClick = () => {
        window.scrollTo(0,0);
        if(mobileVersion){
            setNavBarShow(false);
        }
    }
    const handleNavHub = () => {
        setChangerActive(false);
        setBrand(user.defaultBrand);
        setCountry(user.defaultCountry);
    }
    const navigateAndClose = (pagePath,sectionId) => {
        if(mobileVersion){
            setNavBarShow(false);
        }
    }

    useEffect(() => {
        navigate('/');
    },[loggedIn]);

    if (user.viewUsers === 1){
        return (
            <header style={{top: headerPos}} className={`${styles.headerTextWhite}`}>
            <div className={styles.imgContainer} onClick={handleLogoClick}>
                <span><strong>Goodyear</strong> Retail Brand Hub</span>
            </div>
            <div className={`${styles.burgerMenuButton} ${mobileVersion? styles.displayGrid: styles.hide}`} onClick={toggleNavBar}>
                <Burger />
            </div>
            <nav className={`${navBarShow? styles.displayBlock : styles.hide}`}>
                    <ul>
                        <li className={styles.closeButton}>
                            <a onClick={() => setNavBarShow(false)}><Cross /></a>
                        </li>
                        <li>
                            <NavLink to="home" onClick={handleNavClick}>Home</NavLink>
                        </li>
                        <li>
                            <NavLink to="users" onClick={handleNavClick}>Users</NavLink>
                        </li>
                            {user.viewEU === 1 & user.viewUK === 1? <li><NavLink to="hiq" onClick={handleNavHub}>HiQ</NavLink></li> : ''}
                        <li>
                            {mobileVersion? <UserIconMobile/> : <UserIcon />}
                        </li>
                    </ul>
            </nav>
            </header>
        )
    } else {
        return (
            <header style={{top: headerPos}} className={`${styles.headerTextWhite}`}>
            <div className={styles.imgContainer} onClick={handleLogoClick}>
                <span><strong>Goodyear</strong> Retail Brand Hub</span>
            </div>
            <div className={`${styles.burgerMenuButton} ${mobileVersion? styles.displayGrid: styles.hide}`} onClick={toggleNavBar}>
                <Burger />
            </div>
            <nav className={`${navBarShow? styles.displayBlock : styles.hide}`}>
                    <ul>
                        <li className={styles.closeButton}>
                            <a onClick={() => setNavBarShow(false)}><Cross /></a>
                        </li>
                        <li>
                            <NavLink to="home" onClick={handleNavClick}>Home</NavLink>
                        </li>
                        {user.viewEU === 1 & user.viewUK === 1? <li><NavLink to="hiq" onClick={handleNavHub}>HiQ</NavLink></li> : ''}
                        <li>
                            <UserIcon />
                        </li>
                    </ul>
            </nav>
            </header>
        )
    }
}